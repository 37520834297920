import { LabelValuePair } from 'types/contentTypes'

export const placeHolderOptions: LabelValuePair[] = [
  {
    label: 'Option 1',
    value: 'op1',
  },
  {
    label: 'Option 2',
    value: 'op2',
  },
]

// Used for Radio select options
export const yesOrNo: LabelValuePair[] = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
]

// Ncd options are usually found in a quote(s) response, however some designs have the Ncd select before that call is ever made
export const ncdYears: LabelValuePair[] = [
  {
    label: '0',
    value: '0',
  },
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
  {
    label: '10 +',
    value: '10',
  },
]

export const getBreakdownName = (level: string) => {
  switch (level) {
    case 'Level1':
    case 'Basic':
      return 'National Cover'
    case 'Level2':
    case 'Home Start':
      return 'National plus Doorstep Cover'
    case 'Level3':
    case 'Europe':
      return 'National plus Doorstep and European Cover'
    default:
      return 'No'
  }
}
