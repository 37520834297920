import { Cost } from '../types/cost'
import { IncludedAddOn } from '../types/includedAddon'
import { getBreakdownName } from 'utils/content/options'

export const includedAddonsUtils = (includedAddOns: IncludedAddOn[]) => {
  // Breakdown Names
  const tyaBreakdownNames: any = {
    Level1: 'Breakdown Cover: National',
    Level2: 'Breakdown Cover: National plus Doorstep',
    Level3: 'Breakdown Cover: European',
  }

  const details = () => includedAddOns

  const isBreakdownAvailable = () => {
    const breakdown = includedAddOns.find(item => item.codeIdentifier === 'TMBreakdownCov')

    return breakdown === undefined ? false : true
  }

  const getAdditionalAddOns = (withDrivingAbroad?: 'withDrivingAbroad') => {
    const additional = includedAddOns.filter(item => item.coverageCategoryCode === 'PMVehicleAddGrp')
    // Is both standard and optional. 3 days => standard. 90 days => optional
    const drivingAbroad = includedAddOns.find(item => item.codeIdentifier === 'PMDrivingAbroadCov') as IncludedAddOn

    return withDrivingAbroad ? [...additional, drivingAbroad] : additional
  }

  const getSelectedAddonsAsString = () => {
    const addons = getAdditionalAddOns('withDrivingAbroad')

    const selected = addons.filter(item => item.selected).flatMap(item => item.name)

    if (selected.length > 0) {
      return selected.join(', ')
    }

    return 'None'
  }

  const getAddonById = (codeIdentifier: string) => {
    return includedAddOns.find(item => item.codeIdentifier === codeIdentifier) as IncludedAddOn
  }

  // Addon summary
  const includeCosts = (addon: IncludedAddOn) => {
    if (addon.amount) {
      return {
        ...addon,
        costs: {
          costPerYear: `${addon.amount.amount.toFixed(2)}`,
          costPerMonth: `${(addon.amount.amount / 11).toFixed(2)}`,
        },
      } as IncludedAddOn
    }

    return {
      ...addon,
      costs: {
        costPerYear: '',
        costPerMonth: '',
      },
    } as IncludedAddOn
  }

  const getQuoteAddonSummary = () => {
    const initialAddons = getAdditionalAddOns('withDrivingAbroad')

    let filteredAddons = initialAddons.filter(
      item => item.codeIdentifier !== 'PMDrivingAbroadCov' && item.codeIdentifier !== 'TMBreakdownCov',
    ) as IncludedAddOn[]
    filteredAddons = filteredAddons.map(item => includeCosts(item))

    // Driving abroad
    let drivingAbroad = initialAddons.find(item => item.codeIdentifier === 'PMDrivingAbroadCov') as IncludedAddOn

    if (drivingAbroad.terms[0].chosenTermValue === '3') {
      const amount = drivingAbroad.terms[0].options.find(item => item.name === '90')?.amount as Cost
      drivingAbroad.selected = false
      drivingAbroad.amount = amount
    }

    if (drivingAbroad.terms[0].chosenTermValue === '90') {
      const amount = drivingAbroad.terms[0].options.find(item => item.name === '90')?.amount as Cost

      drivingAbroad.amount = amount
    }

    drivingAbroad = includeCosts(drivingAbroad)

    // breakdown
    const breakdown = initialAddons.find(item => item.codeIdentifier === 'TMBreakdownCov')
    let correctedBreakdown: any[] = []

    if (breakdown !== undefined) {
      const chosenTerm = breakdown.terms[0].chosenTerm
      const levels = breakdown.terms[0].options

      // correct the single breakdown object into 1 object per level

      if (breakdown.selected === true) {
        correctedBreakdown = levels.map(level => {
          if (level.code === chosenTerm) {
            return {
              ...breakdown,
              amount: level.amount,
              name: tyaBreakdownNames[level.code],
              codeIdentifier: level.code,
              selected: true,
              costs: {
                costPerYear: `${level.amount.amount.toFixed(2)}`,
                costPerMonth: `${(level.amount.amount / 11).toFixed(2)}`,
              },
            }
          }

          return {
            ...breakdown,
            amount: level.amount,
            name: tyaBreakdownNames[level.code],
            codeIdentifier: level.code,
            selected: false,
            costs: {
              costPerYear: `${level.amount.amount.toFixed(2)}`,
              costPerMonth: `${(level.amount.amount / 11).toFixed(2)}`,
            },
          }
        })
      } else {
        correctedBreakdown = levels.map(level => {
          return {
            ...breakdown,
            amount: level.amount,
            name: tyaBreakdownNames[level.code],
            codeIdentifier: level.code,
            costs: {
              costPerYear: `${level.amount.amount.toFixed(2)}`,
              costPerMonth: `${(level.amount.amount / 11).toFixed(2)}`,
            },
          }
        })
      }

      return [...filteredAddons, ...(correctedBreakdown as IncludedAddOn[]), drivingAbroad]
    }

    return [...filteredAddons, drivingAbroad]
  }
  // end of Addon summary

  const checkIfKeysIsIncludedAddon = () => {
    const keysIncluded = includedAddOns.find(item => item.codeIdentifier === 'TMKeyProtectCovKFI')
      if (keysIncluded !== undefined){
        return true
       } else {
       return false
       }
  }

  const getPolicyAddonSummary = () => {

    // Note: GW addons arr confusing and inconsistent depending on whether they are coming from quote or policy
    // In a quote you get all addons with all details
    // In policy, you only recieve additional addons the user has actually selected which makes displaying these in the FE a pain the ***

    // Problem 1. It makes working with the policy response undynamic because the FE needs to hardcode the addon names and codes for this brand (see TYA list below)
    // ie if the user has the addon we can grab its details from the object (the name, terms etc)
    // however, we still need to show the UI (ie the name) even when its not been selected by the user, so these values must be hardcoded.
    // If the value ever changes in GW, there is going to be a mismatch here

    // PMDrivingAbroadCov
    // TMBreakdownCov
    // PMLegalExpensesCov
    // TMEnhancedCourtesyCarCov
    // TMKeyProtectCov
    // PMTrailerCov

    // Problem 2. Breakdown addon is not available to all users (if the car is over 15 years old) so its impossible to tell just  by looking at the includedAddons if
    // the breakdown is allowed but not been selected or if the user is not entitled to it.
    // In this case we still show "Breakdown cover: No" in the UI, even to customers who were never offered it in the first place

    // Driving abroad is always in the includedAddons and is both standard (3 days) and optional (90 days)
    const drivingAbroad = getAddonById('PMDrivingAbroadCov')
    const breakdown = getAddonById('TMBreakdownCov')
    const legalExpenses = getAddonById('PMLegalExpensesCov')
    const courtesyCar = getAddonById('TMEnhancedCourtesyCarCov')
    const keyProtect = getAddonById('TMKeyProtectCov')
    const trailer = getAddonById('PMTrailerCov')

    // if keys is in includedAddOns then return different summary object

    let summary = []

    if (checkIfKeysIsIncludedAddon()){
        summary = [
          {
            label: drivingAbroad.name,
            id: drivingAbroad.codeIdentifier,
            value: `${drivingAbroad.terms[0].chosenTermValue} days`,
          },
          ...[
            breakdown
              ? {
                  label: breakdown.name,
                  id: breakdown.codeIdentifier,
                  value: getBreakdownName(breakdown.terms[0].chosenTermValue),
                }
              : {
                  label: 'Breakdown cover',
                  id: 'TMBreakdownCov',
                  // Needs a check for vehicle over 15 years
                  value: 'No',
                },
          ],
          ...[
            legalExpenses
              ? {
                  label: legalExpenses.name,
                  id: legalExpenses.codeIdentifier,
                  value: legalExpenses.terms[0].chosenTermValue,
                }
              : {
                  label: 'Legal Expenses',
                  id: 'PMLegalExpensesCov',
                  value: 'No',
                },
          ],
          ...[
            courtesyCar
              ? {
                  label: courtesyCar.name,
                  id: courtesyCar.codeIdentifier,
                  value: courtesyCar.selected === true ? 'Yes' : 'No',
                }
              : {
                  label: 'Enhanced Courtesy Car Cover',
                  id: 'TMEnhancedCourtesyCarCov',
                  value: 'No',
                },
          ],
          ...[
            trailer
              ? {
                  label: trailer.name,
                  id: trailer.codeIdentifier,
                  value: trailer.terms[0].chosenTermValue,
                }
              : {
                  label: 'Trailers',
                  id: 'PMTrailerCov',
                  value: 'No',
                },
          ],
        ] as { label: string; id: string; value: string }[]

    } else {
        summary = [
          {
            label: drivingAbroad.name,
            id: drivingAbroad.codeIdentifier,
            value: `${drivingAbroad.terms[0].chosenTermValue} days`,
          },
          ...[
            breakdown
              ? {
                  label: breakdown.name,
                  id: breakdown.codeIdentifier,
                  value: breakdown.terms[0].chosenTermValue,
                }
              : {
                  label: 'Breakdown cover',
                  id: 'TMBreakdownCov',
                  // Needs a check for vehicle over 15 years
                  value: 'No',
                },
          ],
          ...[
            legalExpenses
              ? {
                  label: legalExpenses.name,
                  id: legalExpenses.codeIdentifier,
                  value: legalExpenses.terms[0].chosenTermValue,
                }
              : {
                  label: 'Legal Expenses',
                  id: 'PMLegalExpensesCov',
                  value: 'No',
                },
          ],
          ...[
            courtesyCar
              ? {
                  label: courtesyCar.name,
                  id: courtesyCar.codeIdentifier,
                  value: courtesyCar.selected === true ? 'Yes' : 'No',
                }
              : {
                  label: 'Enhanced Courtesy Car Cover',
                  id: 'TMEnhancedCourtesyCarCov',
                  value: 'No',
                },
          ],
          ...[
            keyProtect
              ? {
                  label: keyProtect.name,
                  id: keyProtect.codeIdentifier,
                  value: keyProtect.selected === true ? 'Yes' : 'No',
                }
              : {
                  label: 'Key Protect',
                  id: 'TMKeyProtectCov',
                  value: 'No',
                },
          ],
          ...[
            trailer
              ? {
                  label: trailer.name,
                  id: trailer.codeIdentifier,
                  value: trailer.terms[0].chosenTermValue,
                }
              : {
                  label: 'Trailers',
                  id: 'PMTrailerCov',
                  value: 'No',
                },
          ],
        ] as { label: string; id: string; value: string }[]

    }
    return summary
  }

  return {
    details,
    isBreakdownAvailable,
    getAdditionalAddOns,
    getAddonById,
    getQuoteAddonSummary,
    getPolicyAddonSummary,
    getSelectedAddonsAsString,
    checkIfKeysIsIncludedAddon,
  }
}
