import { translateTypekey } from 'guidewire/formatToDisplay'
import { convertFormValues } from 'guidewire/formatToGuidewire'
import { formatDateObject, booleanToYesOrNo, monthAndYearToIso, getPolicyIds } from 'guidewire/formatTools'
import { portalTools } from 'guidewire/portalTools'

import moment from 'moment'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { setIsLoadingAction } from 'redux/loading/actions'

import { isTokenValid } from 'redux/login/client'
import { onLogout } from 'redux/login/saga'
import { getPoliciesAction, getPolicyByIdAction, setPolicyIdsAction } from 'redux/policy/actions'
import { getPolicies, getPolicyById } from 'redux/policy/client'

import { setPolicyDocumentsAction } from 'redux/policyDocuments/actions'
import { EndPoint } from 'types/endpoint'
import { SelectedPolicy } from 'types/policy'
import { RequestResponse, PolicyDetailsResponse } from 'types/responses'
import { history } from 'utils'

import {
  addBillingDetailsAndGetSagePayUrlAction,
  addRenewalDriverAction,
  amendRenewalAction,
  bindRenewalPaymentAction,
  continueRenewalAction,
  continueToPaymentAction,
  quoteAmendedRenewalDirectBuyAction,
  quoteRenewalAction,
  quoteRenewalDirectBuyAction,
  setPaymentPlanAction,
  setVoluntaryExcessAction,
  updateAddonsAction,
  validateDdiAction,
} from './actions'
import {
  addBillingDetailsAndGetSagePayUrl,
  amendRenewal,
  bindRenewalPayment,
  quoteRenewal,
  quoteRenewalDirectBuy,
  updateAddons,
  updateCoverages,
  updateQuotedAndValidateDDI,
} from './client'

export function* onAmendRenewal(action: {
  payload: {
    current: SelectedPolicy
  }
}) {
  const {
    payload: { current },
  } = action

  const { response: isValid } = yield call(() => isTokenValid())
  let error = false

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string
    const currentPolicy = portalTools(current)
    const policyNumber = currentPolicy.policyId().replace('TYA/', '')
    yield put(setIsLoadingAction.success({ isLoading: true }))
    const { status, data } = yield call(() => amendRenewal(policyNumber, false, access_token))
    if (status !== 200) {
      error = true
      yield call(() => history.push(EndPoint.ERROR_MESSAGE))
    }
    if (!error){
      const mainDriver = data.drivers.find(
        (x: { mainDriver: string | boolean }) => x.mainDriver == true || x.mainDriver === 'yes',
      )
      const additionalDrivers = data.drivers.filter(
        (x: { mainDriver: string | boolean }) => x.mainDriver != true && x.mainDriver !== 'yes',
      )
      const vehicle = data.vehicles[0]
      const formValues = {
        // your details
        title: data.accountHolder.title,
        firstName: data.accountHolder.firstName,
        lastName: data.accountHolder.lastName,
        dateOfBirth: moment(data.accountHolder.dateOfBirth).toLocaleString(),
        ukResident5Years: booleanToYesOrNo(data.accountHolder.ukResident5Years).toLowerCase(),
        ukResidentSinceDate: data.accountHolder.ukResidentSinceDate,
        homeOwner: booleanToYesOrNo(data.accountHolder.homeOwner).toLowerCase(),
        maritalStatus: data.accountHolder.maritalStatus,
        gender: data.accountHolder.gender,
        occupation: {
          label: translateTypekey(data.accountHolder.occupation, 'typekey.OccupationType_itb'),
          value: data.accountHolder.occupation,
        },
        occupationStatus: mainDriver.occupationStatus,
        employersBusiness: {
          label: translateTypekey(mainDriver.employersBusiness as string, 'typekey.EmployersBusinessType_itb'),
          value: mainDriver.occupation,
        },
        // your experience
        licenseType: mainDriver.licenseType,
        testPassed10Years: booleanToYesOrNo(mainDriver.testPassed10Years).toLowerCase(),
        whenPassTest: mainDriver.passedTestMonth
          ? monthAndYearToIso(mainDriver.passedTestMonth, mainDriver.passedTestYear)
          : undefined,
        firstDroveOnProvisionalLicence: undefined,
        drivingQualifications: mainDriver.drivingQualifications,
        medicalConditions: mainDriver.medicalConditions,
        accessToOtherVehicles: booleanToYesOrNo(mainDriver.accessToOtherVehicles).toLowerCase(),
        nonMotoringConvictions: booleanToYesOrNo(mainDriver.nonMotoringConvictions).toLowerCase(),
        cancelledPreviousInsurance: booleanToYesOrNo(mainDriver.cancelledPreviousInsurance).toLowerCase(),
        hasMotorConvictions: booleanToYesOrNo(mainDriver.hasMotorConvictions).toLowerCase(),
        hasMotorClaims: booleanToYesOrNo(mainDriver.hasMotorClaims).toLowerCase(),
        pmMotorConvictions: mainDriver.pmMotorConvictions,
        pmClaims: mainDriver.pmClaims,
        publicID: mainDriver.publicID,
        coverStartDate: formatDateObject(data.baseData.periodStartDate),
        primaryAddress: data.accountHolder.primaryAddress,
        registrationNumber: vehicle.registrationNumber,
        carPurchaseDate: monthAndYearToIso(vehicle.purchasedMonth, vehicle.purchasedYear),
        marketValue: vehicle.marketValue.amount,
        hasBeenModified: booleanToYesOrNo(vehicle.hasBeenModified).toLowerCase(),
        generalAfterMarketMods: vehicle.generalAfterMarketMods,
        bodyWorkMods: vehicle.bodyWorkMods,
        engineMods: vehicle.engineMods,
        wheelsOrTyresMods: vehicle.wheelsOrTyresMods,
        conversionMods: vehicle.conversionMods,
        otherMods: vehicle.otherMods,
        modification: vehicle.modification,
        securityDevices: vehicle.securityDevices,
        nightTimeParking: vehicle.nightTimeParking,
        dayTimeParking: vehicle.dayTimeParking,
        classOfUse: vehicle.classOfUse,
        annualMileage: vehicle.annualMileage,
        registeredKeeper: booleanToYesOrNo(vehicle.registeredKeeper).toLowerCase(),
        vehicleIsLeased: vehicle.registeredKeeper ? undefined : booleanToYesOrNo(vehicle.vehicleIsLeased).toLowerCase(),
        vehicleLeasingCompany: vehicle.registeredKeeper ? undefined : vehicle.vehicleLeasingCompany,
        vehicleOwner: vehicle.registeredKeeper ? undefined : vehicle.vehicleOwner,
        securityTracker: booleanToYesOrNo(vehicle.securityTracker).toLowerCase(),
        imported: booleanToYesOrNo(vehicle.imported).toLowerCase(),
        rightHandDrive: booleanToYesOrNo(vehicle.rightHandDrive).toLowerCase(),
        lessThanEightSeats: booleanToYesOrNo(vehicle.lessThanEightSeats).toLowerCase(),
        ncdYears: vehicle.ncdYears,
      }
      yield put(setIsLoadingAction.success({ isLoading: false }))
      yield put(amendRenewalAction.success({ mainDriver, additionalDrivers, formValues, vehicle, quote: data }))
      yield call(() => history.push(EndPoint.RENEWAL_ABOUT_YOU))
    }
  } else {
    yield call(() => onLogout())
  }
}

export function* onQuoteAmendedRenewalDirectBuyAction(action: {
  payload: {
    current: SelectedPolicy
  }
}) {
  const {
    payload: { current },
  } = action

  const { response: isValid } = yield call(() => isTokenValid())
  let error = false

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string
    const currentPolicy = portalTools(current)
    const policyNumber = currentPolicy.policyId().replace('TYA/', '')
    yield put(setIsLoadingAction.success({ isLoading: true }))
    const { status, data } = yield call(() => amendRenewal(policyNumber, true, access_token))
    if (status !== 200) {
      error = true
      yield call(() => history.push(EndPoint.ERROR_MESSAGE))
      yield put(setIsLoadingAction.success({ isLoading: false }))
    }
    if (status === 200){
      const mainDriver = data.drivers.find(
        (x: { mainDriver: string | boolean }) => x.mainDriver == true || x.mainDriver === 'yes',
      )
      const additionalDrivers = data.drivers.filter(
        (x: { mainDriver: string | boolean }) => x.mainDriver != true && x.mainDriver !== 'yes',
      )
      const vehicle = data.vehicles[0]
      const formValues = {
        // your details
        title: data.accountHolder.title,
        firstName: data.accountHolder.firstName,
        lastName: data.accountHolder.lastName,
        dateOfBirth: moment(data.accountHolder.dateOfBirth).toLocaleString(),
        ukResident5Years: booleanToYesOrNo(data.accountHolder.ukResident5Years).toLowerCase(),
        ukResidentSinceDate: data.accountHolder.ukResidentSinceDate,
        homeOwner: booleanToYesOrNo(data.accountHolder.homeOwner).toLowerCase(),
        maritalStatus: data.accountHolder.maritalStatus,
        gender: data.accountHolder.gender,
        occupation: {
          label: translateTypekey(data.accountHolder.occupation, 'typekey.OccupationType_itb'),
          value: data.accountHolder.occupation,
        },
        occupationStatus: mainDriver.occupationStatus,
        employersBusiness: {
          label: translateTypekey(mainDriver.employersBusiness as string, 'typekey.EmployersBusinessType_itb'),
          value: mainDriver.occupation,
        },
        // your experience
        licenseType: mainDriver.licenseType,
        testPassed10Years: booleanToYesOrNo(mainDriver.testPassed10Years).toLowerCase(),
        whenPassTest: mainDriver.passedTestMonth
          ? monthAndYearToIso(mainDriver.passedTestMonth, mainDriver.passedTestYear)
          : undefined,
        firstDroveOnProvisionalLicence: undefined,
        drivingQualifications: mainDriver.drivingQualifications,
        medicalConditions: mainDriver.medicalConditions,
        accessToOtherVehicles: booleanToYesOrNo(mainDriver.accessToOtherVehicles).toLowerCase(),
        nonMotoringConvictions: booleanToYesOrNo(mainDriver.nonMotoringConvictions).toLowerCase(),
        cancelledPreviousInsurance: booleanToYesOrNo(mainDriver.cancelledPreviousInsurance).toLowerCase(),
        hasMotorConvictions: booleanToYesOrNo(mainDriver.hasMotorConvictions).toLowerCase(),
        hasMotorClaims: booleanToYesOrNo(mainDriver.hasMotorClaims).toLowerCase(),
        pmMotorConvictions: mainDriver.pmMotorConvictions,
        pmClaims: mainDriver.pmClaims,
        publicID: mainDriver.publicID,
        coverStartDate: formatDateObject(data.baseData.periodStartDate),
        primaryAddress: data.accountHolder.primaryAddress,
        registrationNumber: vehicle.registrationNumber,
        carPurchaseDate: monthAndYearToIso(vehicle.purchasedMonth, vehicle.purchasedYear),
        marketValue: vehicle.marketValue.amount,
        hasBeenModified: booleanToYesOrNo(vehicle.hasBeenModified).toLowerCase(),
        generalAfterMarketMods: vehicle.generalAfterMarketMods,
        bodyWorkMods: vehicle.bodyWorkMods,
        engineMods: vehicle.engineMods,
        wheelsOrTyresMods: vehicle.wheelsOrTyresMods,
        conversionMods: vehicle.conversionMods,
        otherMods: vehicle.otherMods,
        securityDevices: vehicle.securityDevices,
        nightTimeParking: vehicle.nightTimeParking,
        dayTimeParking: vehicle.dayTimeParking,
        classOfUse: vehicle.classOfUse,
        annualMileage: vehicle.annualMileage,
        registeredKeeper: booleanToYesOrNo(vehicle.registeredKeeper).toLowerCase(),
        vehicleIsLeased: booleanToYesOrNo(vehicle.vehicleIsLeased).toLowerCase(),
        vehicleLeasingCompany: vehicle.vehicleLeasingCompany,
        vehicleOwner: vehicle.vehicleOwner,
        securityTracker: booleanToYesOrNo(vehicle.securityTracker).toLowerCase(),
        imported: booleanToYesOrNo(vehicle.imported).toLowerCase(),
        rightHandDrive: booleanToYesOrNo(vehicle.rightHandDrive).toLowerCase(),
        lessThanEightSeats: booleanToYesOrNo(vehicle.lessThanEightSeats).toLowerCase(),
        ncdYears: vehicle.ncdYears,
      }
      yield put(setIsLoadingAction.success({ isLoading: false }))
      yield put(quoteRenewalAction.success({ quote: data, activeStep: 2 }))
      yield call(() => history.push(EndPoint.RENEWAL_YOUR_QUOTE))
    }
   
  } else {
    yield call(() => onLogout())
  }
}

export function* onContinueRenewal(action: {
  payload: {
    formValues: any
    vehicle?: any
    redirect: string
    addedDrivers?: any[]
    additionalDrivers?: any[]
  }
}) {
  const {
    payload: { formValues, redirect, vehicle, addedDrivers, additionalDrivers },
  } = action

  yield put(
    continueRenewalAction.success({
      formValues,
      activeStep: redirect === EndPoint.RENEWAL_ADD_DRIVER ? 0 : 1,
      vehicle,
      addedDrivers,
      additionalDrivers,
    }),
  )
  yield call(() => history.push(redirect))
}

export function* onAddRenewalDriver(action: { payload: any[] }) {
  const { payload } = action

  yield put(addRenewalDriverAction.success(payload))
  yield call(() => history.push(EndPoint.RENEWAL_ABOUT_YOU))
}

export function* onQuoteRenewal(action: { payload: any }) {
  const {
    payload: { formValues, vehicle, quote, additionalDrivers, addedDrivers, current },
  } = action
  //build request data for quote renewal call
  const requestData = quote

  const values = convertFormValues(formValues)

  //account holder
  const accountHolder = {
    ...quote.accountHolder,
    gender: formValues.gender,
    occupationStatus: formValues.occupationStatus,
    employersBusiness: formValues.employersBusiness.value,
    occupation: formValues.occupation.value,
    homeOwner: values['homeOwner'],
    maritalStatus: formValues.maritalStatus,
    primaryAddress: formValues.primaryAddress,
    updated: true,
  }
  //main dirver
  const mainDriver = {
    title: formValues.title,
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    occupationStatus: formValues.occupationStatus,
    employersBusiness: formValues.employersBusiness.value,
    occupation: formValues.occupation.value,
    licenseType: formValues.licenseType,
    testPassed10Years: values['testPassed10Years'],
    ...(values['passedTestMonth'] && { passedTestMonth: values['passedTestMonth'] }),
    ...(values['passedTestYear'] && { passedTestYear: values['passedTestYear'] }),
    firstDroveOnProvisionalLicence: undefined,
    drivingQualifications: formValues.drivingQualifications,
    medicalConditions: formValues.medicalConditions,
    accessToOtherVehicles: values['accessToOtherVehicles'],
    nonMotoringConvictions: values['nonMotoringConvictions'],
    cancelledPreviousInsurance: values['cancelledPreviousInsurance'],
    hasMotorConvictions: values['hasMotorConvictions'],
    hasMotorClaims: values['hasMotorClaims'],
    pmMotorConvictions: formValues.pmMotorConvictions,
    pmClaims: formValues.pmClaims,
    gender: formValues.gender,
    homeOwner: values['homeOwner'],
    maritalStatus: formValues.maritalStatus,
    publicID: formValues.publicID,
    mainDriver: true,
  }

  if (formValues.updateOrRemove) {
    mainDriver.updateOrRemove = 'update'
  }

  requestData.drivers = [mainDriver, ...additionalDrivers, ...addedDrivers]
  requestData.vehicles = [
    {
      ...vehicle,
      marketValue: { amount: formValues.marketValue, currency: 'gbp' },
      firstRegisteredMonth: moment(formValues.carPurchaseDate).format('MMMM'),
      firstRegisteredYear: moment(formValues.carPurchaseDate).year(),
      hasBeenModified: values['hasBeenModified'],
      ...(values['hasBeenModified'] === true && { modification: values['modification'] }),
      generalAfterMarketMods: values['generalAfterMarketMods'],
      bodyWorkMods: values['bodyWorkMods'],
      engineMods: values['engineMods'],
      wheelsOrTyresMods: values['wheelsOrTyresMods'],
      otherMods: values['otherMods'],
      securityDevices: values['securityDevices'],
      nightTimeParking: values['nightTimeParking'],
      dayTimeParking: values['dayTimeParking'],
      classOfUse: values['classOfUse'],
      annualMileage: values['annualMileage'],
      registeredKeeper: values['registeredKeeper'],
      securityTracker: values['securityTracker'],
      imported: values['imported'],
      rightHandDrive: values['rightHandDrive'],
      lessThanEightSeats: values['lessThanEightSeats'],
      vehicleIsLeased: values['vehicleIsLeased'],
      vehicleOwner: values['vehicleOwner'],
      vehicleLeasingCompany: values['vehicleLeasingCompany'],
    },
  ]

  requestData.accountHolder = accountHolder

  const { response: isValid } = yield call(() => isTokenValid())
  let error = false

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string

    const currentPolicy = portalTools(current)
    const policyNumber = currentPolicy.policyId().replace('TYA/', '')

    yield put(setIsLoadingAction.success({ isLoading: true }))

    const { status, data } = yield call(() => quoteRenewal(policyNumber, access_token, requestData))

    if (status !== 200) {
      error = true
    }

    yield put(setIsLoadingAction.success({ isLoading: false }))
    if (error) {
      yield call(() => history.push(EndPoint.ERROR_MESSAGE))
    } else {
      yield put(quoteRenewalAction.success({ quote: data, activeStep: 2 }))
      yield call(() => history.push(EndPoint.RENEWAL_ADDONS))
    }
  } else {
    yield call(() => onLogout())
  }
}

export function* onQuoteRenewalDirectBuyAction(action: { payload: { policyId: string; redirect: string } }) {
  const {
    payload: { policyId, redirect },
  } = action

  yield put(setIsLoadingAction.success({ isLoading: true }))

  const { response: isValid } = yield call(() => isTokenValid())

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string

    const { status, data } = yield call(() =>
      quoteRenewalDirectBuy(policyId.replace('TYA/', ''), access_token as string),
    )

    if (status == 200) {
      yield put(setIsLoadingAction.success({ isLoading: false }))
      yield put(quoteRenewalDirectBuyAction.success({ quote: data, activeStep: 2 }))
      yield call(() => history.push(redirect || EndPoint.RENEWAL_YOUR_QUOTE))
    } else {
      console.log('There was an error')
      console.log({ status, data })
    }
  } else {
    yield put(setIsLoadingAction.success({ isLoading: false }))
    yield call(() => onLogout())
  }
}

export function* onContinueToPayment(action: { payload: { billingId: string } }) {
  const {
    payload: { billingId },
  } = action

  yield put(continueToPaymentAction.success({ billingId }))
  yield call(() => history.push('/renewal-documents'))
}

export function* onAddBillingDetailsAndGetSagePayUrl(action: any) {
  const { response: isValid } = yield call(() => isTokenValid())

  yield put(setIsLoadingAction.success({ isLoading: true }))

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string

    const { payload: values } = action
    try {
      const { data } = yield call(() =>
        addBillingDetailsAndGetSagePayUrl(values.requestObject, values.quoteId, access_token),
      )

      yield put(setIsLoadingAction.success({ isLoading: false }))
      yield put(addBillingDetailsAndGetSagePayUrlAction.success(data.bindData.sagePayURL))
      // Might need to move this
      yield put(quoteRenewalAction.success({ quote: data, activeStep: 3 }))
    } catch (error) {
      yield put(setIsLoadingAction.success({ isLoading: false }))
      history.push('/declined?error=purchase_failed')
    }
  } else {
    yield put(setIsLoadingAction.success({ isLoading: false }))
    yield call(() => onLogout())
  }
}

export function* onValidateDdi(action: any): any {
  const { response: isValid } = yield call(() => isTokenValid())

  yield put(setIsLoadingAction.success({ isLoading: true }))

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string
    const { payload: values } = action
    try {
      const { data } = yield call(() => updateQuotedAndValidateDDI(values.requestObject, values.quoteId, access_token))
      yield put(validateDdiAction.success(data))

      yield put(setIsLoadingAction.success({ isLoading: false }))
      yield call(() => history.push(EndPoint.RENEWAL_MONTHLY_MANDATE))
    } catch (error) {
      yield put(setIsLoadingAction.success({ isLoading: false }))
      history.push('/declined?error=purchase_failed')
    }
  } else {
    yield put(setIsLoadingAction.success({ isLoading: false }))
    yield call(() => onLogout())
  }
}

export function* onBindRenewalPayment(action: any): any {
  const { response: isValid } = yield call(() => isTokenValid())

  yield put(setIsLoadingAction.success({ isLoading: true }))

  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string
    const { payload: values } = action
    try {
      const { data } = yield call(() => bindRenewalPayment(values.requestObject, values.quoteId, access_token))

      //update all policies
      const {
        response: { status: policiesStatus, data: policyList },
      } = yield call(() => getPolicies(access_token))

      if (policiesStatus === 200) {
        // Set documents to their own state in redux to make things easier to retrieve
        const documents = (policyList as any[]).map((item: any) => {
          return (item.periods as any[]).flatMap((period: any) => {
            return { policyId: period.policyId, status: period.status, documents: period.documents }
          })
        })

        yield put(setPolicyDocumentsAction.success({ period: documents }))
        //

        yield put(getPoliciesAction.success({ policies: policyList }))

        const policyIds = getPolicyIds(policyList)

        yield put(setPolicyIdsAction.success({ policyIds }))

        const {
          response: { status: policyDetailsStatus, data: policyDetails },
        }: RequestResponse<PolicyDetailsResponse> = yield call(() => getPolicyById(policyIds[0].value, access_token))

        if (policyDetailsStatus === 200) {
          yield put(
            getPolicyByIdAction.success({
              current: policyDetails.currentPeriod,
              renewed: policyDetails.renewedPeriod,
              alternateRenewed: policyDetails.alternateRenewedPeriod,
              autoRenew: policyDetails.autoRenew,
            }),
          )

          // Set the default policy index to sessionstorage for retrieval on page refresh
          sessionStorage.setItem('policy_index', '0')
        }
      }

      yield put(setIsLoadingAction.success({ isLoading: false }))
      yield call(() => history.push(EndPoint.RENEWAL_PAYMENT_COMPLETE))
    } catch (error) {
      yield put(setIsLoadingAction.success({ isLoading: false }))
      history.push('/declined?error=purchase_failed')
    }
  } else {
    yield put(setIsLoadingAction.success({ isLoading: false }))
    yield call(() => onLogout())
  }
}

export function* onSetPaymentPlanAction(action: any) {
  const {
    payload: { selectedPaymentPlan },
  } = action

  try {
    yield put(setPaymentPlanAction.success({ selectedPaymentPlan }))
  } catch (error) {
    console.log(error)
  }
}

export function* onUpdateAddons(action: any) {
  const { payload: values } = action
  yield put(setIsLoadingAction.success({ isLoading: true }))

  const { response: isValid } = yield call(() => isTokenValid())
  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string

    try {
      const { data } = yield call(() =>
        updateAddons(values.requestObject, values.quoteId.replace('TYA/', ''), access_token),
      )

      yield put(quoteRenewalAction.success({ quote: data, activeStep: 2 }))
      yield put(setIsLoadingAction.success({ isLoading: false }))
    } catch (error) {
      console.log(error)
      yield put(setIsLoadingAction.success({ isLoading: false }))
    }
  } else {
    yield put(setIsLoadingAction.success({ isLoading: false }))
    yield call(() => onLogout())
  }
}

export function* onSetVoluntaryExcessAction(action: any) {
  const {
    payload: { requestObject, quoteId },
  } = action

  yield put(setIsLoadingAction.success({ isLoading: true }))

  const { response: isValid } = yield call(() => isTokenValid())
  if (isValid) {
    const access_token = sessionStorage.getItem('access_token') as string

    try {
      const { data, status } = yield call(() => updateCoverages(requestObject, quoteId, access_token))

      if (status === 200) {
        yield put(setVoluntaryExcessAction.success({ quote: data }))

        yield put(setIsLoadingAction.success({ isLoading: false }))
        yield call(() => history.push(EndPoint.RENEWAL_ADDONS))        
      }
    } catch (error) {
      console.log(error)
      yield put(setIsLoadingAction.success({ isLoading: false }))
    }
  } else {
    yield put(setIsLoadingAction.success({ isLoading: false }))
    yield call(() => onLogout())
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(amendRenewalAction.start, onAmendRenewal),
    takeLatest(continueRenewalAction.start, onContinueRenewal),
    takeLatest(addRenewalDriverAction.start, onAddRenewalDriver),
    takeLatest(quoteRenewalAction.start, onQuoteRenewal),
    takeLatest(quoteRenewalDirectBuyAction.start, onQuoteRenewalDirectBuyAction),
    takeLatest(quoteAmendedRenewalDirectBuyAction.start, onQuoteAmendedRenewalDirectBuyAction),
    takeLatest(continueToPaymentAction.start, onContinueToPayment),
    takeLatest(addBillingDetailsAndGetSagePayUrlAction.start, onAddBillingDetailsAndGetSagePayUrl),
    takeLatest(validateDdiAction.start, onValidateDdi),
    takeLatest(bindRenewalPaymentAction.start, onBindRenewalPayment),
    takeLatest(setPaymentPlanAction.start, onSetPaymentPlanAction),
    takeLatest(updateAddonsAction.start, onUpdateAddons),
    takeLatest(setVoluntaryExcessAction.start, onSetVoluntaryExcessAction),
  ])
}
